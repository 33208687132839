<div class="flex flex-col h-full">

  <ng-container *ngIf="shipmentTrail && !loading">

    <div class="flex items-center border rounded-full shadow-sm pt-1.5 px-4-plus pb-1 flex-none">
      <img *ngIf="$any(modeOfTransport)[shipmentTrail.modeOfTransport || '']?.imageUrl"
           src="{{$any(modeOfTransport)[shipmentTrail.modeOfTransport || '']?.imageUrl}}"
           alt="transport mode"
           class="w-5-plus h-5-plus mb-1" />
      <div class="flex-1 ml-3">
        <div class="font-medium text-sm text-primary-700 flex justify-between">
          <span>

            Shipment #{{shipmentTrail.bl}}
            <a href="#"
               [pTooltip]="'Click to open Shipment details in new tab'"
               tooltipPosition="top"
               tooltipStyleClass="-mt-2"
               (click)="openNewTab($event)"><i class="pi pi-external-link text-blue-400 text-2xs ml-2"></i></a>
          </span>
          <span *ngIf="shipmentTrail.freightType">
            <p-chip styleClass="text-xs py-2px text-center text-primary-700 bg-primary-100 -mr-1.5 mb-2px">{{shipmentTrail.freightType}}</p-chip>
          </span>
        </div>
        <div class="flex w-full items-center text-xs text-primary-400">
          <span class="overflow-hidden overflow-ellipsis"
                style="width:10ch; max-height: 2.5em; flex: 0 0 10ch;"
                [pTooltip]="shipmentTrail.origin.locationName"
                tooltipPosition="top"
                tooltipStyleClass="-mt-2">{{shipmentTrail.origin.locationDisplayName}}</span>
          <div class="shipment-progress-small mx-3 w-full">
            <p-progressBar class="progress-bar"
                           [value]="shipmentTrail.progress ?? undefined"
                           [showValue]=false></p-progressBar>
          </div>
          <span class="overflow-hidden overflow-ellipsis text-right"
                style="width:10ch; max-height: 2.5em; flex: 0 0 10ch;"
                [pTooltip]="shipmentTrail.destination.locationName"
                tooltipPosition="top"
                tooltipStyleClass="-mt-2">{{shipmentTrail.destination.locationDisplayName}}</span>
        </div>
      </div>
    </div>
    <p-timeline *ngIf="shipmentTrail.events && shipmentTrail.events.length"
                [value]="shipmentTrail.events"
                class="flex-1 overflow-y-auto mt-7">
      <ng-template pTemplate="content"
                   let-event>
        <div class="text-sm leading-4">
          <div class="font-medium">
            {{event.name}}
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="opposite"
                   let-event>
        <div class="flex flex-col text-sm text-primary-400">
          <div>{{event.date | date: Constants.mediumDateFormat}}</div>
          <div>{{event.date | date: Constants.shortTimeFormat}}</div>
        </div>
      </ng-template>
    </p-timeline>
    <div *ngIf="shipmentTrail.events && !shipmentTrail.events.length"
         class="text-center mt-2 text-primary-300">
      No events
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="flex items-center border rounded-full shadow-sm pt-2 px-4-plus pb-1 flex-none mb-5">
      <p-skeleton shape="circle"
                  styleClass="w-5-plus h-5-plus mb-1"></p-skeleton>
      <div class="flex-1 ml-3">
        <p-skeleton width="7rem"
                    styleClass="mb-1.5"></p-skeleton>
        <p-skeleton height="0.75rem"
                    styleClass="mb-1"></p-skeleton>
      </div>
    </div>
    <div class="flex justify-center mb-3 mx-auto"
         *ngFor="let item of [].constructor(3)">
      <div class="flex flex-1 flex-col items-end mr-2">
        <p-skeleton styleClass="mb-2"
                    width="4rem"></p-skeleton>
        <p-skeleton styleClass="mb-2"
                    width="2rem"
                    height="0.5rem"></p-skeleton>
      </div>
      <div class="flex flex-1 flex-col">
        <p-skeleton styleClass="mb-2"
                    width="9rem"></p-skeleton>
        <p-skeleton styleClass="mb-2"
                    width="5rem"
                    height="0.5rem"></p-skeleton>
      </div>
    </div>
  </ng-container>
</div>